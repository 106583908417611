<template>
  <section class="section-contact">
    <contact-banner :headerHeight="295" :detailPage="true" />
    <template>
      <section class="pt-sm-10 py-lg-0">
        <v-container fluid class="pa-lg-16 px-10 px-sm-0">
          <v-row class="px-lg-16 " justify="center">
            <h3
              class="pt-lg-14 pb-lg-16 py-8 text-lg-h3 text-h5 text-center"
              v-html="$t('contact.contactUsDescription')"
            ></h3>
          </v-row>
          <v-row class="px-lg-16 pb-6 pb-sm-0 mt-0 mt-lg-12">
            <v-col
              cols="12"
              lg="4"
              xs="12"
              class="ml-lg-auto pt-0 px-sm-16 px-lg-0"
            >
              <v-form ref="form" lazy-validation>
                <v-text-field
                  class="v-form-input"
                  v-model="emailMessage.name"
                  :label="$t('contact.name')"
                  :counter="60"
                  :rules="validationRules.nameRules"
                  required
                ></v-text-field>
                <v-text-field
                  class="v-form-input"
                  v-model="emailMessage.email"
                  :rules="validationRules.emailRules"
                  :label="$t('contact.email')"
                  required
                ></v-text-field>
                <v-textarea
                  class="v-form-input"
                  v-model="emailMessage.message"
                  :rules="validationRules.messageRules"
                  :counter="450"
                  :label="$t('contact.message')"
                  no-resize
                  required
                ></v-textarea>
                <v-row class="justify-center justify-lg-start mx-lg-0 py-5">
                  <p
                    style="font-size: 12px;"
                    v-html="$t('contact.recaptchaMessages')"
                  ></p>
                  <v-btn
                    class="mr-lg-4 btn-success "
                    @click="onSubmit"
                    :loading="loading"
                  >
                    {{ $t("contact.btnSend") }}
                  </v-btn>
                </v-row>
              </v-form>
            </v-col>
            <v-col
              cols="12"
              lg="3"
              xs="12"
              class="pt-16 pl-lg-16 v-contact-info mr-lg-auto vertical-separator px-10 pb-sm-10 pb-lg-0"
            >
              <v-row class="justify-center justify-lg-start">
                <h1>{{ $t("contact.phone") }}</h1>
              </v-row>
              <v-row
                class="pb-5 justify-center justify-lg-start white-space-nowrap"
              >
                <p>(809) 994.6127 <span>|</span> (809) 364.6212</p>
              </v-row>
              <v-row class="justify-center justify-lg-start">
                <h1>{{ $t("contact.email") }}</h1>
              </v-row>
              <v-row
                class="pb-5 justify-center justify-lg-start white-space-nowrap"
              >
                <p class="text-center text-lg-left">
                  info@mixart.do <span>|</span> clientes@mixart.do
                </p>
              </v-row>
              <v-row class="justify-center justify-lg-start">
                <h1>{{ $t("contact.address") }}</h1>
              </v-row>
              <v-row class="pb-5 justify-center justify-lg-start">
                <p class="text-center text-lg-left">
                  Av. Simón Bolívar #911 Apto. 202, La Esperilla Santo Domingo,
                  R. D.
                </p>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </template>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "Contact",

  metaInfo() {
    return {
      title: this.$t("contact.contactUsMessage"),
      meta: [
        {
          name: "description",
          content: this.$t("contact.contactUsDescription")
        }
      ]
    };
  },

  data: () => ({
    loading: false,
    emailMessage: {
      name: "",
      email: "",
      message: ""
    },
    elevation: 0
  }),

  computed: {
    validationRules() {
      return {
        nameRules: [
          v => !!v || this.$t("contact.nameRequired"),
          v => (v && v.length <= 60) || this.$t("contact.nameMaxLength")
        ],
        emailRules: [
          v => !!v || this.$t("contact.emailRequired"),
          v => /.+@.+\..+/.test(v) || this.$t("contact.emailIsValid")
        ],
        messageRules: [
          v => !!v || this.$t("contact.messageRules"),
          v => (v && v.length <= 450) || this.$t("contact.messageMaxLength")
        ]
      };
    }
  },

  methods: {
    ...mapActions("contacts", ["sendEmailMessage"]),
    async onSubmit() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();
      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha();

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          container: "contact-message-modal",
          confirmButton:
            "btn-success v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default",
          title: "h6"
        },
        buttonsStyling: false
      });
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          await this.sendEmailMessage({
            ...this.emailMessage,
            recaptcha_token: token
          });
          this.$refs.form.reset();
          swalWithBootstrapButtons.fire({
            title: this.$t("contact.contactSuccessMessage"),
            text: this.$t("contact.contactSuccessDescription"),
            confirmButtonText: this.$t("contact.conctactMessageButton"),

            icon: "success"
          });
        } catch {
          swalWithBootstrapButtons.fire({
            title: this.$t("contact.contactErrorMessage"),
            text: this.$t("contact.contactErrorDescription"),
            icon: "error",
            confirmButtonText: this.$t("contact.conctactMessageButton")
          });
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>
